.logo-side {
	width: 200px;
}

.nav-link.active {
  color: #e18d8d !Important;
}

.lead {
	font-size: 2rem!Important;
}

@media only screen and (max-width: 1000px) {
	.logo-side {
		width: 70px;
	}
}